/* eslint-disable react-hooks/exhaustive-deps */
import Detail from "./DetailPresta";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { isMobile } from "react-device-detect";
import ContactUs from "./ContactUs";
// import ScrollArea from "react-scrollbar";
// var ScrollArea = require("react-scrollbar/no-css");
// import ScrollArea from "react-scrollbar/no-css";
// import ScrollArea from 'react-scrollbar/dist/no-css';
import maintenance from "../assets/analyse/maintenance.png";
import productman from "../assets/analyse/productman.png";
import optimmetrics from "../assets/analyse/optimmetrics.png";
import integration from "../assets/dev/integration.png";
import intranet from "../assets/dev/intranet.png";
import webapp from "../assets/dev/webapp.png";
import sitevitrine from "../assets/dev/sitevitrine.png";
import appios from "../assets/dev/appios.png";
import ecommerce from "../assets/dev/ecommerce.png";
import animweb from "../assets/design/animweb.png";
import infographie from "../assets/design/infographie.png";
import uxaudit from "../assets/design/uxaudit.png";
import uxdesign from "../assets/design/uxdesign.png";
import idvisuelle from "../assets/creation/idvisuelle.png";
import logocreation from "../assets/creation/logocreation.png";
import print from "../assets/creation/print.png";
import tract from "../assets/creation/tract.png";

const MenuOrDetail = ({
  analyse,
  dev,
  design,
  crea,
  menu,
  offset,
  isSlide
}) => {
  const [props, setProps] = useState({
    desc: "",
    image: [],
    categorie: [],
    timelineDesc: ""
  });

  // let proops = {
  //     // desc: "",
  //     // imaage: ["/Analyse/maintenance.png"],
  //     // categorie: ["/Analyse/maintenance.png"],
  //     // timelineDesc: ""
  // };

  // if (analyse) {
  //     proops = {
  //         desc: (
  //             <div>
  //                 Notre rôle consiste à prendre le temps de comprendre votre
  //                 modèle d'affaires afin de vous propser des stratégies
  //                 numériques créatives et performantes. Nos tactiques
  //                 avant-gardistes correspondent au marché actuel,
  //                 s'harmonisent avec les réalités opérationnelles de nos
  //                 clients et éliminent les risques non nécessaires.
  //                 <br />
  //                 <br /> Questionner et comprendre les enjeux. Proposer un
  //                 ordre. Fixer des objectifs. Déployer des moyens. Atteindre
  //                 les cibles. Designer de manière intégrée. Créer des vitrines
  //                 de visibilité performantes. Obtenir un message fort, clair
  //                 et cohérent
  //             </div>
  //         ),
  //         imaage: [
  //             { link: "/Analyse/maintenance.png" },
  //             { link: "/Analyse/productman.png" },
  //             { link: "/Analyse/optimmetrics.png" }
  //         ],
  //         categorie: [
  //             "COMPRÉHENSION DU MODÈLE D'AFFAIRES (BMC)",
  //             "ANALYSE DE PROPOSITION DE VALEUR",
  //             "ANALYSE AVANCÉE DE CLIENTÈLE",
  //             "ANALYSE DE LA CONCURENCE",
  //             "ÉLABORATION D'OBJECTIF QUANTIFIABLES (KPI)",
  //             "ÉLABORATION DU PLAN D'ACTIONS NUMÉRIQUES",
  //             "CONFIGURATION D'OUTILS D'ANALYSE AVANCÉS",
  //             "CONSEILS ET ACCOMPAGNEMENT CONTINU",
  //             "PLANIFICATION STRATÉGIQUES",
  //             "PLAN DE COMMUNICATION",
  //             "POSITIONEMENT DE MARQUE",
  //             "RELATIONS PUBLIQUES",
  //             "PLACEMENT MÉDIA",
  //             "CONCEPTION-RÉDACTION"
  //         ],
  //         timelineDesc: (
  //             <div>
  //                 Notre rôle consiste à prendre le temps de comprendre votre
  //                 modèle d'affaires afin de vous propser des stratégies
  //                 numériques créatives et performantes. Nos tactiques
  //                 avant-gardistes correspondent au marché actuel,
  //                 s'harmonisent avec les réalités opérationnelles de nos
  //                 clients et éliminent les risques non nécessaires.
  //             </div>
  //         )
  //     };
  // }

  useEffect(() => {
    if (analyse)
      setProps({
        ...props,
        desc: (
          <div>
            Notre rôle consiste à prendre le temps de comprendre votre modèle
            d'affaires afin de vous proposer des stratégies numériques créatives
            et performantes. Nos tactiques avant-gardistes correspondent au
            marché actuel, s'harmonisent avec les réalités opérationnelles de
            nos clients et éliminent les risques non nécessaires.
          </div>
        ),
        image: [maintenance, productman, optimmetrics],
        categorie: [
          "COMPRÉHENSION DU MODÈLE D'AFFAIRES (BMC)",
          "ANALYSE DE PROPOSITION DE VALEUR",
          "ANALYSE AVANCÉE DE CLIENTÈLE",
          "ANALYSE DE LA CONCURENCE",
          "ÉLABORATION D'OBJECTIF QUANTIFIABLES (KPI)",
          "ÉLABORATION DU PLAN D'ACTIONS NUMÉRIQUES",
          "CONFIGURATION D'OUTILS D'ANALYSE AVANCÉS",
          "CONSEILS ET ACCOMPAGNEMENT CONTINU",
          "PLANIFICATION STRATÉGIQUES",
          "PLAN DE COMMUNICATION",
          "POSITIONEMENT DE MARQUE",
          "RELATIONS PUBLIQUES",
          "PLACEMENT MÉDIA",
          "CONCEPTION-RÉDACTION"
        ],
        timelineDesc: (
          <div>
            Questionner et comprendre les enjeux. Proposer un ordre. Fixer des
            objectifs. Déployer des moyens. Atteindre les cibles. Designer de
            manière intégrée. Créer des vitrines de visibilité performantes.
            Obtenir un message fort, clair et cohérent
          </div>
        )
      });

    if (dev)
      setProps({
        ...props,
        desc: (
          <div>
            Pour nous, l'innovation technologique se doit d'être au service de
            vos objectifs d'affaires : une recette qui a fait ses preuves auprès
            de notre clientèle. Nous nous entourons des meilleurs talents sur le
            marché et utilisons une méthodologie qui simplifie les processus
            dans un environnement sécurisé et facile à comprendre.
          </div>
        ),
        image: [integration, intranet, webapp, sitevitrine, appios, ecommerce],
        categorie: [
          "COMPRÉHENSION DU MODÈLE D'AFFAIRES (BMC)",
          "ANALYSE DE PROPOSITION DE VALEUR",
          "ANALYSE AVANCÉE DE CLIENTÈLE",
          "ANALYSE DE LA CONCURENCE",
          "ÉLABORATION D'OBJECTIF QUANTIFIABLES (KPI)",
          "ÉLABORATION DU PLAN D'ACTIONS NUMÉRIQUES",
          "CONFIGURATION D'OUTILS D'ANALYSE AVANCÉS",
          "CONSEILS ET ACCOMPAGNEMENT CONTINU",
          "PLANIFICATION STRATÉGIQUES",
          "PLAN DE COMMUNICATION",
          "POSITIONEMENT DE MARQUE",
          "RELATIONS PUBLIQUES",
          "PLACEMENT MÉDIA",
          "CONCEPTION-RÉDACTION"
        ],
        timelineDesc: (
          <div>
            Définir les besoins et les exigences.
            <br />
            Analyser. Concevoir le système.
            <br />
            Programmer. Tester.
            <br />
            Déployer. Maintenir la pérennité.
          </div>
        )
      });
    if (crea)
      setProps({
        ...props,
        desc: (
          <div>
            Nous sommes une équipe de créateurs au service de votre marque,
            mettant à votre profit notre imagination et notre efficacité. Nous
            avons le désir de créer des interfaces offrant une expérience
            utilisateur stimulante et des storyellings à la fois touchants et
            stratégiques. L'imagination, l'innovation et la créativité sont au
            service d'une commercialisation performante et mesurable.
          </div>
        ),
        image: [idvisuelle, logocreation, print, tract],
        categorie: [
          "COMPRÉHENSION DU MODÈLE D'AFFAIRES (BMC)",
          "ANALYSE DE PROPOSITION DE VALEUR",
          "ANALYSE AVANCÉE DE CLIENTÈLE",
          "ANALYSE DE LA CONCURENCE",
          "ÉLABORATION D'OBJECTIF QUANTIFIABLES (KPI)",
          "ÉLABORATION DU PLAN D'ACTIONS NUMÉRIQUES",
          "CONFIGURATION D'OUTILS D'ANALYSE AVANCÉS",
          "CONSEILS ET ACCOMPAGNEMENT CONTINU",
          "PLANIFICATION STRATÉGIQUES",
          "PLAN DE COMMUNICATION",
          "POSITIONEMENT DE MARQUE",
          "RELATIONS PUBLIQUES",
          "PLACEMENT MÉDIA",
          "CONCEPTION-RÉDACTION"
        ],
        timelineDesc: (
          <div>
            Centré sur l'humain. Approche omnicanal.
            <br />
            Comportement du consommateur.
            <br />
            Méthodes analytiques. Data.
            <br />
            Compréhension des écosystèmes. Fluidité.
            <br />
            Compétitivité et profitabilité.
            <br />
            Levier de valeur.
          </div>
        )
      });
    if (design)
      setProps({
        ...props,
        desc: (
          <div>
            Notre rôle consiste à prendre le temps de comprendre votre modèle
            d'affaires afin de vous propser des stratégies numériques créatives
            et performantes. Nos tactiques avant-gardistes correspondent au
            marché actuel, s'harmonisent avec les réalités opérationnelles de
            nos clients et éliminent les risques non nécessaires.
          </div>
        ),
        image: [animweb, infographie, uxaudit, uxdesign],
        categorie: [
          "COMPRÉHENSION DU MODÈLE D'AFFAIRES (BMC)",
          "ANALYSE DE PROPOSITION DE VALEUR",
          "ANALYSE AVANCÉE DE CLIENTÈLE",
          "ANALYSE DE LA CONCURENCE",
          "ÉLABORATION D'OBJECTIF QUANTIFIABLES (KPI)",
          "ÉLABORATION DU PLAN D'ACTIONS NUMÉRIQUES",
          "CONFIGURATION D'OUTILS D'ANALYSE AVANCÉS",
          "CONSEILS ET ACCOMPAGNEMENT CONTINU",
          "PLANIFICATION STRATÉGIQUES",
          "PLAN DE COMMUNICATION",
          "POSITIONEMENT DE MARQUE",
          "RELATIONS PUBLIQUES",
          "PLACEMENT MÉDIA",
          "CONCEPTION-RÉDACTION"
        ],
        timelineDesc: (
          <div>
            Un accompagnement de bout en bout du processus de design, d'abord
            sur l'arborescence, puis sur la structuration des wireframes et
            enfin sur la création des mockups en multidevices.
          </div>
        )
      });
    return () => {};
  }, [analyse, dev, crea, design]);

  // console.log('props2' + props.image);

  if (analyse || dev || design || crea) {
    return <Detail props={props} isSlide={isSlide} />;
  } else {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.2 }}
        style={{ pointerEvents: "hover" }}
      >
        <ScrollMenu
          data={menu}
          inertiaScrolling={true}
          inertiaScrollingSlowdown={0.5}
          // onSelect={() => this.setState({ select: true })}
          translate={isMobile ? 50 : 1000}
        />
        {/* <ScrollArea horizontal={false}>{menu}</ScrollArea> */}
        <ContactUs isSlide={isSlide} />
      </motion.div>
    );
  }
};

export default MenuOrDetail;
