import React from "react";
import "./App.css";
import GlobalFonts from "./fonts";
import { isMobile } from "react-device-detect";
import ScrollableAnchor, { goToAnchor } from "react-scrollable-anchor";
import HeaderBar from "./components/HeaderBar";
import Background from "./components/Background";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ContactForm from "./components/ContactForm";
import Projects from "./components/Projects";
import Presta from "./components/Presta";

class App extends React.Component {
  state = {
    slide: false,
    contact: true,
    categorie: "",
    categorieList: [
      {
        value: "Analyse"
      },
      {
        value: "Développement"
      },
      {
        value: "Design"
      },
      {
        value: "Création"
      },
      {
        value: "Recrutement"
      }
    ]
  };

  isSlide = ({ isRecrute = false }) => {
    this.setState({ slide: !this.state.slide });
    if (isRecrute) this.setState({ categorie: "Recrutement" });
    else this.setState({ categorie: "" });
  };

  isContact = state => {
    console.log("contact" + this.state.contact);
    console.log("state" + state);
    this.setState({ contact: state });
  };

  Presta = () => {
    goToAnchor("presta");
  };

  handle = props => {
    this.setState({ categorie: props.target.value });
  };

  render() {
    const { slide, categorie, categorieList } = this.state;

    return (
      <body className="main">
        <GlobalFonts />
        <div
          style={{
            backgroundColor: "black",
            opacity: 0.7,
            height: "100vh",
            width: "100vw",
            position: "fixed",
            display: slide ? "" : "none",
            margin: 0,
            zIndex: 20
          }}
          onClick={() => this.isSlide({})}
        />
        <div style={{ display: slide ? "" : "none" }}>
          <ContactForm
            slide={slide}
            setSlide={() => this.setState({ slide: !slide })}
            handle={this.handle}
            isSlide={this.isSlide}
            categorie={categorie}
            categorieList={categorieList}
          />
        </div>
        <div style={{ height: "100vh" }}>
          <Background />
          <HeaderBar isSlide={this.isSlide} />
          <Header Presta={this.Presta} />
        </div>
        <div>
          <Projects />
        </div>
        <ScrollableAnchor id={"presta"}>
          <div style={{ height: isMobile ? "" : "45vw" }}>
            <Presta isContact={this.isContact} isSlide={this.isSlide} />
          </div>
        </ScrollableAnchor>

        <Footer />
      </body>
    );
  }
}

export default App;
