import { isMobile } from 'react-device-detect';

const style_function = () => {
	return {
		ContactUsContainer: {
			width: isMobile ? '100%' : '100%',
			textAlign: 'center',
			margin: 'auto'
		},
		title: {
			color: 'black',
			fontSize: isMobile ? '3.3vh' : '2.5vw',
			fontFamily: 'butler'
		},

		border: {
			border: 'solid',
			height: '7vw',
			width: '30vw',
			borderColor: '#E82566',
			fontFamily: 'avenir',
			position: 'absolute',
			borderWidth: 5,
			left: '35vw',
			cursor: 'pointer'
		},

		contact: {
			height: '100%',
			margin: 'auto',
			padding: 'auto',
			color: '#E82566',
			fontSize: isMobile ? '1.3vh' : '0.6vw',
			fontSpacing: 10,
			textAlign: 'center',
			marginTop: '3vw',
			letterSpacing: '0.1vw',
			fontFamily: 'avenir',
			fontWeight: '900'
		}
	};
};

export default style_function;
