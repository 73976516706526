import React from "react";
import { isMobile } from "react-device-detect";
import "../styles/css/Projects.css";
import uuid from "uuid/v1";
import img_youggy from "../assets/img_youggy.png";
import logo_youggy from "../assets/logo_youggy.jpg";
import img_sketchtomail from "../assets/img_sketchtomail.png";
import logo_sketchtomail from "../assets/logo_sketchtomail.png";
import img_foncia from "../assets/img_foncia.png";
import logo_foncia from "../assets/logo_foncia.jpg";

const text = [
  {
    titleProjects:
      "Nous planifions le développement de votre produit from scratch.",
    nameProjects: "Youggy",
    description: (
      <div>
        Nous avons créé l'application de A à Z, de l'intégration des
        <br />
        éléments graphiques (front-end) à la gestion de la base de
        <br />
        données et serveur (back-end) ainsi que son déploiement.
      </div>
    ),
    imgPres: img_youggy,
    classImgPres: "imgProjects",
    imgLogo: logo_youggy,
    classImgLogo: "logo",
    reverse: false
  },
  {
    titleProjects: "Nous nous chargeons d'améliorer votre produit.",
    nameProjects: "Sketch2Mail",
    description: (
      <div>
        Nous avons assisté la phase de conception du produit sur
        <br />
        l'optimisation de son algorithme et la restitution des
        <br />
        informations aux utilisateurs finaux.
      </div>
    ),
    imgPres: img_sketchtomail,
    classImgPres: "imgProjectsReverse",
    imgLogo: logo_sketchtomail,
    classImgLogo: "logoReverse",
    reverse: true
  },
  {
    titleProjects:
      "Nous créons et optimisons le design UX et UI de vos interfaces.",
    nameProjects: "Foncia",
    description: (
      <div>
        Nous avons appliqué nos recherches pour apporter une
        <br />
        meilleure expeérience aux utilisateurs des outils métiers du
        <br />
        réseau Foncia.
      </div>
    ),
    imgPres: img_foncia,
    classImgPres: "imgProjects",
    imgLogo: logo_foncia,
    classImgLogo: "logo",
    reverse: false
  }
];

const Return = () => {
  return (
    <div>
      <div className={isMobile ? "primaryTitleMobile" : "primaryTitleDesktop"}>
        Prêts à satisfaire tous vos désirs digitaux?
      </div>

      <div
        className={isMobile ? "secondaryTitleMobile" : "secondaryTitleDesktop"}
      >
        Une équipe entière dédiée à votre Produit
      </div>
      <Projects />
    </div>
  );
};

const Projects = () =>
  text.map(el => {
    const {
      titleProjects,
      nameProjects,
      description,
      imgPres,
      classImgPres,
      imgLogo,
      classImgLogo,
      reverse
    } = el;

    return (
      <Project
        key={uuid}
        titleProjects={titleProjects}
        nameProjects={nameProjects}
        description={description}
        imgPres={imgPres}
        classImgPres={classImgPres}
        imgLogo={imgLogo}
        classImgLogo={classImgLogo}
        reverse={reverse}
      />
    );
  });

const Project = props => {
  return (
    <div className={`projectsContainer${props.reverse ? "Grey" : ""}`}>
      <div style={{ paddingBottom: "4vw" }} />
      <div className={`row${props.reverse ? "Reverse" : ""}`}>
        <div style={{ width: "70%" }}>
          <div>
            <img
              className={`${props.classImgPres}${isMobile ? "Mobile" : ""}`}
              src={props.imgPres}
              alt="icon presta"
            />
          </div>
        </div>
        <div
          className={`containerTitleProjects${props.reverse ? "Reverse" : ""}`}
        >
          <div
            className={
              isMobile ? "titleProjectsMobile" : "titleProjectsDesktop"
            }
          >
            {props.titleProjects}
            <div>
              <img
                className={props.classImgLogo}
                src={props.imgLogo}
                alt="icon logo"
              />
              <div
                style={{
                  fontWeight: "400",
                  fontFamily: "Avenir",
                  marginBottom: "1vw"
                }}
              >
                {props.nameProjects}
              </div>
              <div
                className={
                  isMobile ? "descriptionMobile" : "descriptionDesktop"
                }
              >
                {props.description}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "10vw" }} />
    </div>
  );
};

export default Return;
