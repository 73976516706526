import React from "react";
import { isMobile } from "react-device-detect";
import style_function from "../styles/js/style_footer";
import whatsApp from "../assets/logo_whatsapp.png";
import bg_footer_mobile from "../assets/bg_footer_mobile.jpg";
import bg_footer from "../assets/footer.jpg";

const Footer = () => {
  const style_footer = style_function();
  return (
    <div>
      {/* <div style={{ paddingBottom: isMobile ? "4vh" : "" }} /> */}
      {isMobile ? (
        <img
          style={style_footer.img}
          src={bg_footer_mobile}
          alt="footer mobile"
        />
      ) : (
        <img style={style_footer.img} src={bg_footer} alt="footer big size" />
      )}
      <div style={{ paddingBottom: isMobile ? "34vh" : "8vw" }} />
      <div className="footerContainer">
        <div style={style_footer.subTitle}>Par mail</div>
        <div style={{ paddingBottom: isMobile ? "1vh" : "1vw" }} />

        <div style={style_footer.mail}>namaste@loota.fr</div>
        <div style={{ paddingBottom: isMobile ? "2vh" : "2vw" }} />
        <div style={style_footer.line} />
        <div style={{ paddingBottom: isMobile ? "4vh" : "4vw" }} />
        <div style={style_footer.flexWhatsApp}>
          <img
            style={{ height: isMobile ? "3vh" : "2.5vw" }}
            src={whatsApp}
            alt="logo"
          />
          <div style={{ width: "1vw" }} />
          <div style={style_footer.whatsAppText}>Via WhatsApp</div>
        </div>
        <div style={{ paddingBottom: isMobile ? "3vh" : "4vw" }} />
        <div style={style_footer.flexCredit}>
          <div style={style_footer.creditText}>
            Made with{" "}
            <span role="img" aria-label="emoji red flower">
              🌺
            </span>{" "}
            by Loota
          </div>
          <div style={{ width: "3vw", height: isMobile ? "2vh" : "" }} />
          <div style={{ ...style_footer.creditText, paddingTop: 2.5 }}>
            Garry Valette / Florian Carré / Flavian Magrit / Lucas Tsiaraso
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
