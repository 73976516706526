import React from 'react';
import '../styles/css/HeaderBar.css';
import Button from '@material-ui/core/Button';
import { isMobile } from 'react-device-detect';
import { ReactComponent as Logo } from '../assets/Loota_text.svg';
import { motion } from 'framer-motion';
// import 'rsuite/lib/styles/index.less';

const styles = {
	hire: {
		background: '#E82566',
		height: '3vh',
		width: '5vw',
		borderRadius: 3,
		border: 0,
		color: 'white',
		padding: 0,
		paddingLeft: '0.9vh',
		paddingRight: '0.9vh',
		fontSize: '0.9vh'
		//   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
	},
	mainHeaderBar: {
		height: isMobile ? '9vh' : '6vw'
	}
};

const HeaderBar = props => {
	return (
		<div style={styles.mainHeaderBar}>
			<div className='flexRow'>
				<Logo
					style={{
						marginTop: 'auto',
						marginBottom: 'auto',
						height: isMobile ? '3vh' : '1.8vw',
						width: 'auto'
					}}
				/>
				<motion.div
					className='hireHeader'
					whileHover={{ scale: 1.1 }}
					whileTap={{
						scale: 0.9
					}}
					onClick={() => props.isSlide({ isRecrute: true })}
				>
					<Button style={styles.hire}>ON RECRUTE</Button>
				</motion.div>
				<motion.a
					className='contactHeader'
					onClick={() => props.isSlide({})}
					whileHover={{ scale: 1.1 }}
					style={{ cursor: 'pointer' }}
				>
					Nous contacter
				</motion.a>
			</div>
		</div>
	);
};

export default HeaderBar;
