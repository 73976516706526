/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import style_function from "../styles/js/style_detailPresta";

const Detail = ({ props, isSlide }) => {
  const { image, categorie } = props;
  const style_detailPresta = style_function();

  console.log("props " + props.image);
  const imgs = image.map(el => {
    // console.log("edded " + link);
    return (
      <img
        src={el}
        key={el}
        style={{
          height: isMobile ? "30%" : "25%",
          width: isMobile ? "30%" : "25%",
          paddingRight: isMobile ? "1vh" : "1vw",
          paddingBottom: "0.5vw",
          justifyContent: "left"
        }}
        alt="details icon"
      />
    );
  });

  let count = 0;
  const categories = categorie.map(el => {
    count++;
    return (
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          paddingBottom: "0.1vw"
        }}
      >
        <div
          style={{
            color: "#3F88FB",
            fontFamily: "Avenir-Black",
            fontWeight: "900",
            fontSize: isMobile ? "1vh" : "0.5vw",
            letterSpacing: "0.05vw"
          }}
        >
          {count < 10 ? "0" : ""}
          {count}
        </div>
        <div style={{ width: "0.7vw" }} />
        <div
          style={{
            color: "black",
            fontFamily: "Avenir-Black",
            fontWeight: "900",
            fontSize: isMobile ? "1vh" : "0.5vw",
            letterSpacing: "0.05vw"
          }}
        >
          {el}
        </div>
      </div>
    );
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.2 }}
      style={{ margin: "auto" }}
    >
      <div style={style_detailPresta.descContainer}>
        <div style={style_detailPresta.position}>
          <div>{imgs}</div>
          <div style={style_detailPresta.descriptionLeft}>{props.desc}</div>
        </div>

        <div style={style_detailPresta.position}>
          <div style={style_detailPresta.descriptionRight}>
            {props.timelineDesc}
          </div>
          <div>{categories}</div>
          <div style={{ textAlign: "left" }}>
            <div style={style_detailPresta.cherchez}>
              C'est ce que vous cherchez ?
            </div>
            <a
              style={style_detailPresta.borderContact}
              onClick={() => isSlide({})}
              href="javascript:;"
            >
              <div style={style_detailPresta.contactLittle}>NOUS CONTACTER</div>
            </a>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: isMobile ? "" : "3vw" }} />
    </motion.div>
  );
};

export default Detail;
