import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import MenuOrDetail from './MenuOrDetail';
import Categorie from './Categorie';
import '../styles/css/Presta.css';
import style_function from '../styles/js/style_presta';
import maintenance from '../assets/analyse/maintenance.png';
import productman from '../assets/analyse/productman.png';
import optimmetrics from '../assets/analyse/optimmetrics.png';
import integration from '../assets/dev/integration.png';
import intranet from '../assets/dev/intranet.png';
import webapp from '../assets/dev/webapp.png';
import sitevitrine from '../assets/dev/sitevitrine.png';
import appios from '../assets/dev/appios.png';
import ecommerce from '../assets/dev/ecommerce.png';
import animweb from '../assets/design/animweb.png';
import infographie from '../assets/design/infographie.png';
import uxaudit from '../assets/design/uxaudit.png';
import uxdesign from '../assets/design/uxdesign.png';
import idvisuelle from '../assets/creation/idvisuelle.png';
import logocreation from '../assets/creation/logocreation.png';
import print from '../assets/creation/print.png';
import tract from '../assets/creation/tract.png';
import uuid from 'uuid/v1';

// list of items
const list = [
	maintenance,
	productman,
	optimmetrics,
	integration,
	intranet,
	webapp,
	sitevitrine,
	appios,
	ecommerce,
	animweb,
	infographie,
	uxaudit,
	uxdesign,
	idvisuelle,
	logocreation,
	print,
	tract
];

// One item component
// selected prop will be passed
const MenuItem = ({ image }) => {
	return (
		<motion.div className={`menu-item`} whileHover={{ scale: 1.1 }}>
			<img
				style={{
					height: '20vh',
					width: 'auto',
					margin: '2vh'
				}}
				scale={0.9}
				src={image}
				draggable='false'
				alt='icon description'
			/>
		</motion.div>
	);
};

// All items component
// Important! add unique key
export const Menu = list =>
	list.map(el => {
		return <MenuItem image={el} key={uuid()} />;
	});

class Presta extends Component {
	constructor(props) {
		super(props);
		// call it again if items count changes
		this.menuItems = Menu(list);
	}

	state = {
		Analyse: false,
		Développement: false,
		Design: false,
		Création: false,
		Webmarketing: false,
		offset: 1000,
		selected: '',
		// seconds: 0,
		select: false
	};

	onSelect = key => {
		this.setState({ selected: key });
	};

	IsRender = props => {
		if (!this.IsTrue()) return true;
		else if (props === true) return true;
		else return false;
	};

	HandleClick = param => {
		console.log(param);
		for (const key in this.state) {
			if (key !== param && this.state.hasOwnProperty(key))
				this.setState({ [key]: false });
			else this.setState({ [key]: !this.state[key] });
		}
		this.IsTrue();
	};

	IsTrue = () => {
		for (const key in this.state) {
			console.log(this.state[key]);
			if (this.state[key] === true && this.state.hasOwnProperty(key)) {
				this.props.isContact(false);
				return true;
			}
		}
		if (
			!this.state.Analyse &&
			!this.state.Développement &&
			!this.state.Création &&
			!this.state.Design
		)
			this.props.isContact(true);
		return false;
	};

	// tick() {
	//     if (!this.state.select) {
	//         if (this.state.seconds < 6200) {
	//             this.setState(state => ({
	//                 seconds: state.seconds + 2
	//             }));
	//         }
	//     }
	// }

	// componentDidMount() {
	//     this.interval = setInterval(() => this.tick(), 100);
	// }

	// componentWillUnmount() {
	//     clearInterval(this.interval);
	// }

	render() {
		const { Analyse, Développement, Design, Création } = this.state;

		// Create menu from items
		const menu = this.menuItems;
		const style_presta = style_function();

		return (
			<div className='App'>
				<div style={{ paddingBottom: isMobile ? '1vh' : '4vw' }} />
				<div style={style_presta.PrestaContainer}>
					<div style={{ paddingBottom: isMobile ? '1vh' : '1vw' }} />

					<div style={style_presta.title}>
						Des prestations digitales à la carte
					</div>
					<div style={{ paddingBottom: isMobile ? '1vh' : '1vw' }} />
					<div style={style_presta.categorie}>
						<Categorie
							who='Analyse'
							state={Analyse}
							HandleClick={this.HandleClick}
							color='#2ECC71'
						/>
						<Categorie
							who='Développement'
							state={Développement}
							HandleClick={this.HandleClick}
							color='#3F88FB'
						/>
						<Categorie
							who='Design'
							state={Design}
							HandleClick={this.HandleClick}
							color='#FFC312'
						/>
						<Categorie
							who='Création'
							state={Création}
							HandleClick={this.HandleClick}
							color='#E82566'
						/>
					</div>
					<div
						style={{ paddingBottom: isMobile ? '2.5vh' : '2.5vw' }}
					/>
					<MenuOrDetail
						analyse={Analyse}
						dev={Développement}
						crea={Création}
						design={Design}
						menu={menu}
						isSlide={this.props.isSlide}
					/>
				</div>
			</div>
		);
	}
}

export default Presta;
