import React from "react";
import "../styles/css/Header.css";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";

const styles = {
  img: {
    height: isMobile ? "40vh" : "auto",
    width: isMobile ? "auto" : "100vw",
    position: "absolute",
    overflowX: "hidden",
    left: 0,
    zIndex: -1
  },
  headerContainer: {
    width: isMobile ? "80%" : "25%",
    margin: isMobile ? "auto" : "",
    textAlign: "left",
    marginLeft: "10%",
    marginRight: "10%"
  },
  subTitle: {
    color: "white",
    fontSize: isMobile ? "1.5vh" : "0.7vw",
    fontFamily: "Avenir-Black",
    fontWeight: "900"
  },
  title: {
    color: "white",
    fontSize: isMobile ? "3.3vh" : "2.5vw",
    fontFamily: "butler"
  },
  text: {
    color: "white",
    fontSize: isMobile ? "1.5vh" : "0.7vw",
    fontFamily: "Avenir-Book"
  }
};

const Header = props => {
  return (
    <div style={{ height: isMobile ? "" : "100vh" }}>
      {/* <img style={styles.img} src="/Header.jpg" /> */}
      <div style={{ paddingBottom: isMobile ? "1vh" : "10vw" }} />
      <div style={styles.headerContainer}>
        <div style={styles.subTitle}>
          Analyse — Développement — Design — Création — Webmarketing
        </div>
        <div style={{ paddingBottom: isMobile ? "1vh" : "1vw" }} />

        <div style={styles.title}>
          Nous sommes experts de vos produits digitaux
        </div>
        <div style={{ paddingBottom: isMobile ? "2.5vh" : "2.5vw" }} />
        <div style={styles.text}>
          Nous adressons aux startups, PME et grands groupes pour leur offrir
          des solutions web et mobile en accord avec les technologie et usages
          actuels. Notre expertise en développement et design favorise la
          performance, la croissance, l'optimasition de vos produits digitaux.
        </div>
        <div style={{ paddingBottom: isMobile ? "1.5vh" : "1.5vw" }} />

        <motion.a
          style={{
            border: "solid",
            height: "5vh",
            width: isMobile ? "60vw" : "10vw",
            borderColor: "#00FFFF",
            fontFamily: "Avenir-Book",
            position: "absolute",
            borderWidth: 5
          }}
          whileHover={{ scale: 1.05 }}
          onClick={() => props.Presta()}
        >
          <div
            style={{
              height: "100%",
              margin: "auto",
              padding: "auto",
              color: "white",
              color: "#00FFFF",
              fontSize: isMobile ? "1vh" : "0.5vw",
              fontSpacing: 10,
              textAlign: "center",
              marginTop: "1.9vh",
              letterSpacing: "0.1vw",
              fontFamily: "Avenir-Black",
              fontWeight: "900"
            }}
          >
            VOIR NOS SERVICES
          </div>
        </motion.a>
      </div>
    </div>
  );
};

export default Header;
