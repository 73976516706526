import React from 'react';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import style_function from '../styles/js/style_contactUs';

const ContactUs = props => {
	const style_contactUs = style_function();

	return (
		<div style={{ height: isMobile ? '20vw' : '20vw' }}>
			{/* <img style={Styles.img} src="/ContactUs.jpg" /> */}
			<div style={{ paddingBottom: isMobile ? '1vh' : '4vw' }} />
			<div style={style_contactUs.ContactUsContainer}>
				<div style={{ paddingBottom: isMobile ? '1vh' : '1vw' }} />

				<div style={style_contactUs.title}>Discutons !</div>
				<div style={{ paddingBottom: isMobile ? '2.5vh' : '2.5vw' }} />

				<motion.a
					style={style_contactUs.border}
					onClick={() => props.isSlide({})}
					whileHover={{ scale: 1.02 }}
				>
					<div style={style_contactUs.contact}>NOUS CONTACTER</div>
				</motion.a>
			</div>
		</div>
	);
};

export default ContactUs;
