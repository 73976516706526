import { isMobile } from 'react-device-detect';

const style_function = () => {
	return {
		img: {
			height: 'auto',
			width: '100vw',
			position: 'absolute',
			overflowX: 'hidden',
			left: 0,
			zIndex: -1
		},
		subTitle: {
			color: 'white',
			fontSize: isMobile ? '1.5vh' : '1vw',
			fontFamily: 'avenir',
			textAlign: 'center'
		},
		mail: {
			color: '#00FFFF',
			fontSize: isMobile ? '3vh' : '2vw',
			fontFamily: 'avenir',
			fontWeight: '900',
			letterSpacing: '0.1vw',
			textAlign: 'center'
		},
		line: {
			borderBottomStyle: 'solid',
			width: isMobile ? '3.5vh' : '3.5vw',
			borderBottomHeight: 2,
			color: 'white',
			margin: 'auto'
		},
		flexWhatsApp: {
			flex: 1,
			flexDirection: 'row',
			display: 'flex',
			justifyContent: 'center'
		},
		whatsAppText: {
			color: 'white',
			fontSize: isMobile ? '1.5vh' : '1vw',
			fontFamily: 'avenir',
			marginTop: 'auto',
			marginBottom: 'auto'
		},
		flexCredit: {
			flex: 1,
			flexDirection: isMobile ? 'column' : 'row',
			textAlign: 'center',
			display: 'flex',
			justifyContent: 'center',
			paddingLeft: '2vw'
		},
		creditText: {
			color: 'white',
			fontSize: isMobile ? '1.3vh' : '0.8vw',
			fontFamily: 'avenir',
			marginTop: 'auto',
			marginBottom: 'auto'
		}
	};
};

export default style_function;
