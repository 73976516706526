/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { motion } from 'framer-motion';
import posed from 'react-pose';
import style_function from '../styles/js/style_categorie';

const Box = posed.div({
	hoverable: true,
	pressable: true,
	init: {
		scale: 1,
		color: '#8C8C8C'
	},
	hover: {
		scale: 1.2,
		color: ({ color }) => color
	},
	press: {
		scale: 0.9
	},
	clicked: {
		scale: 1.2,
		color: ({ color }) => color
	}
});

const Categorie = ({ who, state, color, HandleClick }) => {
	const style_categorie = style_function();

	return (
		<a
			href='javascript:;'
			style={{
				...style_categorie.categorieText,
				color: state ? color : '#8C8C8C'
			}}
			onClick={() => HandleClick(who)}
		>
			<Box pose={state ? 'clicked' : 'init'} color={color}>
				{who}
			</Box>
			{state ? (
				<motion.div
					animate={{ scale: 1.3 }}
					transition={{ duration: 0.2 }}
					style={{ color: color, height: 10, scale: 0 }}
				>
					•
				</motion.div>
			) : (
				<div style={{ height: 10 }} />
			)}
		</a>
	);
};

export default Categorie;
