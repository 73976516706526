import React from 'react';
import { isMobile } from 'react-device-detect';
import BgMobile from '../assets/bg_mobile.jpg';
import Bg from '../assets/bg.jpg';

const styles = {
	background: {
		height: 'auto',
		width: '100vw',
		position: 'absolute',
		overflowX: 'hidden',
		right: 0,
		zIndex: -10
	},
	mainBackground: {
		position: 'absolute',
		width: '100vw',
		zIndex: -10
	}
};

const Background = () => {
	return (
		<div className='mainBackground'>
			{isMobile ? (
				<img
					id='image'
					style={styles.background}
					src={BgMobile}
					alt='background mobile'
				/>
			) : (
				<img
					id='image'
					style={styles.background}
					src={Bg}
					alt='background big size'
				/>
			)}
		</div>
	);
};

export default Background;
