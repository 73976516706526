import { createGlobalStyle } from 'styled-components';

import AvenirBook01 from './Avenir-Book-01.ttf';
import AvenirBlack03 from './Avenir-Black-03.ttf';
import ButlerUltraLight from './Butler_Ultra_Light.otf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Avenir-Book';
        src: local('AvenirBook01'),
        local('Avenir Book 01'),
        url(${AvenirBook01}) format('truetype');
        font-weight: 400;
    }
    @font-face {
        font-family: 'Avenir-Black';
        src: local('AvenirBlack03'),
        local('Avenir Black 03'),
        url(${AvenirBlack03}) format('truetype');
        font-weight: 900;
    }
    @font-face {
        font-family: 'Butler';
        src: local('ButlerUltraLight'),
        local('Butler Ultra Light'),
        url(${ButlerUltraLight});
    }
`;