import { isMobile } from "react-device-detect";

const style_function = () => {
  return {
    PrestaContainer: {
      width: isMobile ? "100%" : "100%",
      textAlign: "center",
      margin: "auto"
    },
    title: {
      color: "black",
      fontSize: isMobile ? "3.3vh" : "2.5vw",
      fontFamily: "butler"
    },
    categorie: {
      flexDirection: "row",
      display: "flex",
      textAlign: "center",
      margin: "auto",
      justifyContent: "center",
      cursor: "pointer"
    },
    categorieText: {
      fontSize: isMobile ? "1.5vh" : "0.8vw",
      fontFamily: "avenir",
      paddingRight: "2vw",
      fontWeight: "400",
      textDecoration: "none"
    }
  };
};

export default style_function;
