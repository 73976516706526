import React from "react";
import { motion } from "framer-motion";
import { TextField, MenuItem } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { MdClose } from "react-icons/md";
import Button from "@material-ui/core/Button";

const styles = {
  input: {
    width: "100%",
    fontSize: "34vh",
    color: "red",
    marginTop: "2vh"
  },

  send: {
    height: "100%",
    margin: "auto",
    padding: "auto",
    color: "#E82566",
    fontSize: isMobile ? "1.3vh" : "0.6vw",
    fontSpacing: 10,
    textAlign: "center",
    letterSpacing: "0.1vw",
    fontFamily: "Avenir-Black",
    fontWeight: "900"
  },

  borderSend: {
    border: "solid",
    height: "4vw",
    width: "15vw",
    borderColor: "#E82566",
    fontFamily: "Avenir-Book",
    borderWidth: 5,
    borderRadius: 0,
    top: "2vw"
  }
};

const ContactForm = ({ slide, ...props }) => {
  return (
    <motion.div
      style={{
        position: "fixed",
        left: "100vw",
        top: 0,
        backgroundColor: "white",
        height: "100vh",
        width: isMobile ? "100vw" : "45vw",
        zIndex: 30
      }}
      animate={{ x: slide ? (isMobile ? "-90vw" : "-40vw") : 0 }}
    >
      <motion.div
        style={{
          height: "3vh",
          width: "3vh",
          margin: "2vh",
          cursor: "pointer"
        }}
        whileHover={{ scale: 1.2, rotate: -90 }}
        whileTap={{
          scale: 0.9
        }}
        onClick={() => props.setSlide()}
      >
        <div style={{ margin: "auto", textAlign: "center" }}>
          <MdClose size={"3vh"} color={"#8C8C8C"} />
        </div>
      </motion.div>
      <div style={{ marginLeft: "10%", marginRight: "20%" }}>
        <div className="contactFormTitle">Faites-nous part de votre projet</div>
        <div className="inputContainer">
          <TextField
            style={styles.input}
            id="standard-select-currency"
            select
            label="Intéressé par ..."
            value={props.categorie}
            onChange={prop => props.handle(prop)}
          >
            {props.categorieList.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div>
          <TextField
            // className="inputContainer"
            style={styles.input}
            id="standard-basic"
            label="Nom"
            margin="dense"
          />
        </div>
        <div>
          <TextField style={styles.input} id="standard-basic" label="Email" />
        </div>
        <div>
          <TextField
            style={styles.input}
            id="standard-basic"
            label="Message (facultatif)"
          />
        </div>
        <Button
          style={styles.borderSend}
          onClick={() => props.isSlide({})}
          whileHover={{ scale: 1.02 }}
        >
          <div style={styles.send}>ENVOYER</div>
        </Button>
      </div>
    </motion.div>
  );
};

export default ContactForm;
