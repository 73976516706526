import { isMobile } from "react-device-detect";

const style_function = () => {
  return {
    img: {
      height: isMobile ? "30%" : "25%",
      width: isMobile ? "30%" : "25%",
      paddingRight: isMobile ? "1vh" : "1vw",
      paddingBottom: "0.5vw",
      justifyContent: "left"
    },

    containerImg: {
      flexDirection: "row",
      display: "flex",
      paddingBottom: "0.1vw"
    },

    nb: {
      color: "#3F88FB",
      fontFamily: "avenir",
      fontWeight: "900",
      fontSize: isMobile ? "1vh" : "0.5vw",
      letterSpacing: "0.05vw"
    },

    skills: {
      color: "black",
      fontFamily: "avenir",
      fontWeight: "900",
      fontSize: isMobile ? "1vh" : "0.5vw",
      letterSpacing: "0.05vw"
    },

    descContainer: {
      flexDirection: isMobile ? "column" : "row",
      display: isMobile ? "relative" : "flex",
      textAlign: "left",
      justifyContent: "center",
      marginLeft: isMobile ? "15%" : "auto"
    },

    position: {
      width: isMobile ? "80%" : "25%",
      paddingLeft: "0.5vw",
      paddingRight: "0.5vw"
    },

    descriptionLeft: {
      paddingLeft: "0.5vw",
      paddingTop: "2vw",
      paddingBottom: "2vw",
      fontSize: isMobile ? "1.2vh" : "0.8vw",
      fontFamily: "avenir",
      textAlign: "left",
      color: "#8C8C8C"
    },

    descriptionRight: {
      fontSize: isMobile ? "1.1vh" : "0.7vw",
      fontFamily: "avenir",
      textAlign: "left",
      color: "#8C8C8C",
      paddingBottom: "2vw"
    },

    cherchez: {
      marginTop: "1vw",
      fontSize: isMobile ? "1.8vh" : "1.1vw",
      fontWeight: "900",
      fontFamily: "avenir"
    },

    borderContact: {
      marginTop: isMobile ? "1.5vh" : "1vw",
      borderStyle: "solid",
      height: isMobile ? "3vh" : "2.5vw",
      width: isMobile ? "40vw" : "10vw",
      borderColor: "#E82566",
      fontFamily: "avenir",
      borderWidth: 5,
      position: "absolute",
      textDecoration: "none"
    },

    contactLittle: {
      marginTop: isMobile ? "0.7vh" : "0.9vw",
      color: "#E82566",
      fontSize: isMobile ? "1vh" : "0.5vw",
      fontSpacing: 10,
      textAlign: "center",
      letterSpacing: "0.1vw",
      fontFamily: "avenir",
      fontWeight: "900"
    }
  };
};

export default style_function;
